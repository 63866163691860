<template>
  <div class="map-container" :class="{'thumbnail' : latlng && property && !address && !isModalMap, 'review-map': isModalMap && latlng && property && !address, 'whole-map': !isModalMap && latlng && !property && !address }">
    <div id="map" class="map" ref="reviewElement"></div>
  </div>
</template>
<script>
// eslint-disable-next-line simple-import-sort/imports
import 'leaflet/dist/leaflet.css';

import L from 'leaflet';
import marker from '@/assets/images/filled-marker-icon-svg.svg';
import { VUE_APP_LEAFLET_LAYER_URL } from '../../../config';

// tmp only
const OS_MAP_API_KEY = 'mO9BPArB5oA2Ai8YIPs4nZQza4GhGFHh';

export default {
  emits: ['show-details'],
  props: ['address', 'latlng', 'isManual', 'property', 'isModalMap', 'addresses'],
  data() {
    return {
      map: null,
    };
  },
  mounted() {
    if (this.latlng && this.property && !this.address && !this.isModalMap) {
      // property list map thumbnail
      this.initThumbnailMap();
    } else if (this.latlng && this.property && !this.address && this.isModalMap) {
      // suposeed on thumbnail click view, modal map
      this.initReviewMap();
    } else if (this.addresses && this.addresses?.length) {
      // property list map view
      this.initViewWholeMap();
    } else {
      // map when adding property
      this.initMap();
    }
  },
  methods: {
    initMap() {
      this.map = L.map('map', { attributionControl: false }).setView(this.address.latlng, 16);

      const icon = new L.Icon({
        iconUrl: marker,
        iconAnchor: [14, 40],
        popupAnchor: [1, -34],
      });

      L.tileLayer(`${VUE_APP_LEAFLET_LAYER_URL}?key=${OS_MAP_API_KEY}`).addTo(this.map);

      this.marker = L.marker(this.address.latlng, { icon });
      if (this.isManual) {
        this.marker.addTo(this.map)
          .unbindPopup()
          .bindPopup(this.popupTemplate)
          .openPopup();
      }
      this.map.on('movestart', () => {
        this.map.getCenter().toString();
      });

      this.$watch('address', (newAddress, oldAddress) => {
        if (newAddress !== oldAddress) {
          this.map.setView(newAddress.latlng).zoomIn(30);
          this.marker
            .setLatLng(newAddress.latlng)
            .unbindPopup()
            .bindPopup(this.popupTemplate)
            .openPopup();
          this.map.on('movestart', () => {
            this.map.getCenter().toString();
          }).zoomIn(30);
        }
        if (!newAddress.UPRN) {
          this.map.removeLayer(this.marker);
        } else {
          this.marker.addTo(this.map)
            .unbindPopup()
            .bindPopup(this.popupTemplate)
            .openPopup();
        }
      });
    },
    initThumbnailMap() {
      this.map = L.map('map', { attributionControl: false, zoomControl: false, dragging: false }).setView(this.latlng, 30);

      L.tileLayer(`${VUE_APP_LEAFLET_LAYER_URL}?key=${OS_MAP_API_KEY}`).addTo(this.map);

      this.map.on('movestart', () => {
        this.map.getCenter().toString();
      });

      const container = L.DomUtil.get('map');
      if (container !== null) {
        container.id = null;
      }
    },
    initReviewMap() {
      this.map = L.map('map').setView(this.latlng, 30);
      // this.map = L.map(this.$refs.reviewElement, { zoomControl: false }).setView(this.latlng, 30);
      const icon = new L.Icon({
        iconUrl: marker,
        iconAnchor: [14, 20],
        popupAnchor: [-55, -20],
      });

      L.tileLayer(`${VUE_APP_LEAFLET_LAYER_URL}?key=${OS_MAP_API_KEY}`).addTo(this.map);

      this.marker = L.marker(this.latlng, { icon }).unbindPopup().bindPopup(this.popupTemplate).openPopup();
      this.marker.addTo(this.map)
        .unbindPopup()
        .bindPopup(this.popupTemplate)
        .openPopup();
    },
    initViewWholeMap() {
      const { addresses } = this;

      const baseLatLang = [51.509865, -0.118092];

      this.map = L.map('map', {
        attributionControl: false,
        zoomControl: false,
        zoom: 1,
        zoomAnimation: false,
        fadeAnimation: true,
        markerZoomAnimation: true
      }).setView(baseLatLang, 7);

      const icon = new L.Icon({
        iconUrl: marker,
        iconAnchor: [1, 35],
        popupAnchor: [11, -34],
        iconSize: [25, 30]
      });

      L.tileLayer(`${VUE_APP_LEAFLET_LAYER_URL}?key=${OS_MAP_API_KEY}`, {
        maxZoom: 24,
        maxNativeZoom: 20,
        opacity: 0.5
      }).addTo(this.map);

      addresses.forEach((address) => {
        const latlang = new Array(...address.latlang);
        /* eslint-disable global-require */
        const template = `
        <div class="marker-container flex-column jc-c ai-c">
          <div class="marker-container__row flex-row ai-fs">
            <div class="marker-container__thumbnail-container flex-row ai-c jc-c">
              <el-image fit="cover" :src="${require('@/assets/images/property-sample-image.jpg')}"></el-image>
            </div>
            <div class="marker-container__row flex-column ai-fs">
              <p class="marker-container__address-text default-text">${address.address}</p>
              <p class="marker-container__postcode-text default-text">${address.postcode}</p>
            </div>
          </div>
        </div>`;
        /* eslint-disable global-require */
        // <b>${address?.address}</b><br />UPRN: ${address?.uprn}

        this.marker = L.marker(latlang, { icon });

        this.marker.addTo(this.map)
          .unbindPopup()
          .bindPopup(template)
          .openPopup();

        this.marker.on('click', () => {
          // this.showDetails();
          // console.log('hello world');
          this.$emit('show-details', address);
        });

        // this.map.on('movestart', () => {
        //   this.map.getCenter().toString();
        // });

        // L.tileLayer(`${VUE_APP_LEAFLET_LAYER_URL}?key=${OS_MAP_API_KEY}`).addTo(this.map);
      });

      // this.map.setView(this.latlng).zoomIn(30);
      // this.marker
      //   .setLatLng(this.latlng)
      //   .unbindPopup()
      //   .bindPopup(this.popupTemplate)
      //   .openPopup();

      // this.map = L.map(this.$refs.reviewElement, { zoomControl: false }).setView(this.latlng, 30);
      // const icon = new L.Icon({
      //   iconUrl: marker,
      //   iconAnchor: [14, 40],
      //   popupAnchor: [1, -34],
      // });
      // L.control.zoom({
      //   position: 'topright'
      // }).addTo(this.map);
      // this.map.on('movestart', () => {
      //   this.map.getCenter().toString();
      // });

      L.tileLayer(`${VUE_APP_LEAFLET_LAYER_URL}?key=${OS_MAP_API_KEY}`).addTo(this.map);

      // this.marker = L.marker(this.latlng, { icon }).unbindPopup().bindPopup(this.popupTemplate).openPopup();
      this.map.on('movestart', () => {
        this.map.getCenter().toString();
      });
      // this.marker.addTo(this.map)
      //   .unbindPopup()
      //   .bindPopup(this.popupTemplate)
      //   .openPopup();
      const container = L.DomUtil.get('map');
      if (container !== null) {
        container.id = null;
      }
    },
    showDetails() {
    }
  },
  computed: {
    popupTemplate() {
      if (this.property) {
        return `<b>${this.property?.address}</b><br />UPRN: ${this.property?.uprn}`;
      }
      return `<b>${this.address?.ADDRESS}</b><br />UPRN: ${this.address?.UPRN}`;
    }
  },
};
</script>
<style lang="scss" scoped>
.map-container {
  display: flex;
  flex: 1;
  margin: 125px 0 0 0;
}

.map {
  width: 100%;
  max-height: 700px;
  border-radius: 15px;
  box-shadow: 0px 0px 10px 0px rgba(42, 42, 42, 0.02);
}

.map-container.thumbnail {
  margin: 0;
  width: 85px;
  max-width: 85px;
  height: 85px;
  min-width: 85px;

  .map {
    max-height: 85px !important;
  }
}
.map-container.review-map {
  min-width: 400px;
  min-height: 300px;
  margin: 0;

  .map {
    max-height: 300px !important;
  }
}
.map-container.whole-map {
  margin: 0;
  height: 100%;
  width: 100%;

  .map {
    max-height: 100% !important;
  }
}
:deep(.marker-container) {
  width: auto;
}
:deep(.marker-container__row) {
  width: 100%;
  gap: .5rem;
}
:deep(.marker-container__thumbnail-container) {
  width: 70px;
  height: 70px;
  min-width: 70px;
  border-radius: 10px;
  overflow: hidden;
  background: rgba(12, 15, 74, 0.05);

  .el-image {
    width: 100%;
    height: 100%;
  }
}
:deep(.marker-container__address-text) {
  font-size: 16px;
  font-weight: 700;
  line-height: 20px;
  letter-spacing: 0.25px;
}
:deep(.marker-container__postcode-text) {
  font-size: 12px;
  font-weight: 600;
  line-height: 22px;
  letter-spacing: 0.25px;
  color: rgba(12, 15, 74, 0.5);
}
:deep(.marker-container__cta-text) {
  width: 100%;
  text-align: center;
  font-size: 14px;
  font-weight: 700;
  line-height: 25px;
  letter-spacing: 0.25px;
  color: #FFA500;
}
</style>
